<template>
  <div class="user-card" v-if="user">
    <div class="user-card-content" :style="`--picture:url(${user.picture})`">
      <div class="user-card-left">
        <UserAvatar :user="user" :style="{ margin: 'var(--mpadding)' }" />
      </div>
      <div class="user-card-right">
        <div class="user-card-status">
          <div class="user-card-user">
            <div class="user-card-info-label skeleton">{{ user.name }}</div>
            <div class="user-card-info-email skeleton">{{ user.email }}</div>
          </div>
          <div class="user-card-resume">
            <div>
              <span class="mount_label">{{ date_locale(user.identity.verifyStartDate) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AdminProfileVerifyCardFile :user="user" fileType="picture" v-if="visiblePictureCard" @update="visiblePictureCard = false" />
    <AdminProfileVerifyCardFile :user="user" fileType="cover" v-if="visibleCoverCard" @update="visibleCoverCard = false" />
  </div>
</template>

<script>
export default {
  props: ["user"],
  components: {
    AdminProfileVerifyCardFile: () => import("./AdminProfileVerifyCardFile.vue"),
  },
  data: function() {
    return { visiblePictureCard: true, visibleCoverCard: true };
  },
};
</script>

<style lang="scss">
.user-card {
  position: relative;
  margin: $mpadding 0;
  @include shadow;
  border-radius: $mpadding/2;
  overflow: hidden;
  &-content {
    display: flex;
    width: 100%;
    background-color: #fff;
  }
  &-admin {
    background-color: #fff;
  }
  .user-documents {
    padding: $mpadding;
    border-top: solid 1px $alto;
    border-bottom: solid 1px $alto;
  }
  &-left {
    display: flex;
  }
  &-icon {
    background-color: var(--color);
    background-image: var(--picture);
    background-size: cover;
    background-position: center;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    font-size: var(--iconSize);
    margin: $mpadding;
    .iconic {
      @include Flex(inherit, center, center);
    }
  }
  &-info {
    padding: $mpadding $mpadding;
    display: flex;
    align-items: center;
    &-label {
      font-weight: bold;
      font-size: 105%;
    }
    &-email {
      opacity: 0.8;
    }
    &-reason {
      margin: $mpadding/2 0 0 0;
      font-weight: bold;
      font-size: 90%;
      display: flex;
      gap: $mpadding/2;
      .label {
        color: $primary-color;
      }
      .post {
        opacity: 0.7;
        cursor: pointer;
      }
    }
    &-message {
      margin: $mpadding/2 $mpadding $mpadding/2 0;
      opacity: 0.8;
    }
    .progress-bar {
      margin-top: $mpadding/2;
    }
  }
  &-right {
    flex: 1;
    padding: $mpadding 0 $mpadding 0;
    .mount {
      font-size: 120%;
      font-weight: bold;
      margin: $mpadding/2 $mpadding $mpadding/2 0;
      .iconic {
        display: inline-flex;
        margin: 0 0 $mpadding/2 0;
      }
    }
    .sign {
      font-weight: bold;
      margin: 0 $mpadding/4 0 0;
      font-size: 80%;
    }
    .mount_label {
      margin: 0 $mpadding $mpadding/2 0;
      font-size: 85%;
      opacity: 0.7;
      display: block;
    }
    .tooltiptext {
      font-size: 70%;
    }
  }
  &-status {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
  &-resume {
    text-align: right;
  }
  &-comments {
    padding: 0 $mpadding $mpadding $mpadding;
    border-top: solid 1px $alto;
    .message-box {
      [textarea] {
        border-radius: $mpadding;
        background-color: rgba(9, 30, 66, 0.01);
        min-height: auto;
        padding: $mpadding;
        margin: $mpadding 0 0 0;
      }
    }
    .confirm {
      margin: $mpadding 0 0 0;
    }
  }
}
</style>
